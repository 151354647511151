<template>
    <v-container fluid>
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <v-card flat class="background_color_transparent">
                <v-card-text class="py-0">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-row class="flex-nowrap">
                                <v-col cols="12" class="mt-10">
                                    <v-btn :to="{name: 'company'}">
                                        <v-icon color="primary">mdi-less-than</v-icon>
                                    </v-btn>
                                    <span class="title_menu ml-5">{{ heading }} </span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-row class="flex-nowrap">
                                <v-col cols="12" class="mt-8">
                                    <v-tabs v-model="tab"
                                            background-color="transparent"

                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="primary accent-4" @change="setLanguage" class="pt-0">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3" md="2" class="text-center">
                            <make-logo v-model="logo" aspect-ratio="1"/>
                        </v-col>
                        <v-col cols="12" sm="9" md="10">
                            <v-row>
                                <v-col class="py-0" cols="12" sm="8">
                                    <ValidationProvider ref="company" rules="required|min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('company_name')}}</div>
                                        <v-text-field v-model="company" type="text"
                                                      :error-messages="errors"
                                                      :error="!valid" :disabled="loading"
                                                      :label="$t('company_name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="4">
                                    <div class="input_label">{{$t('name_director')}}</div>
                                    <ValidationProvider ref="name_director" rules="min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="name_director" type="text"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('name_director')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="8">
                                    <div class="input_label">{{$t('company_name_legal')}}</div>
                                    <ValidationProvider ref="name_legal" rules="required|min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="name_legal" type="text"
                                                      :error-messages="errors"
                                                      :error="!valid" :disabled="loading"
                                                      :label="$t('company_name_legal')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="4">
                                    <div class="input_label">{{$t('Email')}}</div>
                                    <ValidationProvider ref="email" rules="email|min:5|max:50" v-slot="{ errors, valid }">
                                        <v-text-field v-model="email" type="email" :error-messages="errors"
                                                      :disabled="loading" label="Email"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0" cols="12" sm="4">
                            <div class="input_label">{{$t('property')}}</div>
                            <ValidationProvider ref="property" rules="required" v-slot="{ errors, valid }">
                                <v-select v-model="property" :disabled="loading" :items="propertyItems"
                                          :error="!valid" :error-messages="errors"
                                          item-text="name" item-value="id"
                                          :label="$t('property')"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                          clearable></v-select>
                            </ValidationProvider>
                        </v-col>


                        <v-col class="py-0" cols="12" sm="4">
                            <ValidationProvider ref="phone" rules="required|phone"
                                                v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('phone_number_manager')}}</div>
                                <v-text-field v-model="phoneRaw" type="tel"
                                              v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                              :error-messages="errors" :error="!valid"
                                              :disabled="loading"
                                              :label="$t('phone_number_manager')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="4">
                            <div class="input_label">{{$t('company_phone_number')}}</div>
                            <ValidationProvider ref="phone_additional" rules="phone"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="phoneAdditionalRaw" type="tel"
                                              v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                              :error-messages="errors" :error="!valid"
                                              :disabled="loading"
                                              :label="$t('company_phone_number')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <div class="input_label">{{$t('address_legal')}}</div>
                            <ValidationProvider ref="address_legal" rules="required|min:3|max:255"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="address_legal" type="text" :error-messages="errors"
                                              :error="!valid" :disabled="loading"
                                              :label="$t('address_legal')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <div class="input_label">{{$t('address_actually')}}</div>
                            <ValidationProvider ref="address_actually" rules="min:3|max:255"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="address_actually" type="text"
                                              :error-messages="errors"
                                              :disabled="loading"
                                              :label="$t('address_actually')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="4">
                            <div class="input_label">{{$t('bin_iin')}}</div>
                            <ValidationProvider ref="bin_iin" rules="required|numeric|length:12|bin_iin"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="bin_iin" type="text" :error-messages="errors"
                                              :error="!valid" :disabled="loading"
                                              :label="$t('bin_iin')"
                                              autocomplete="off"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="4">
                            <div class="input_label">{{$t('contract_number')}}</div>
                            <ValidationProvider ref="contract_number" rules="required|min:1|max:255"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="contract_number" type="text" :error-messages="errors"
                                              :error="!valid" :disabled="loading"
                                              :label="$t('contract_number')"
                                              autocomplete="off"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="4">
                            <v-dialog ref="dateDialogContract" v-model="dateDialogContract" width="290px">
                                <v-date-picker ref="pickerDateContract" v-model="date_contract"
                                               first-day-of-week="1" :locale="lang"
                                               @change="dateDialogContract = false">
                                </v-date-picker>
                            </v-dialog>
                            <ValidationProvider ref="dateContract" rules="required|date_format"
                                                v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('date_contract')}}</div>
                                <v-text-field v-model="date_contract" :error-messages="errors"
                                              :error="!valid" :disabled="loading"
                                              :label="$t('date_contract')"
                                              readonly
                                              @click.stop="dateDialogContract = !dateDialogContract"
                                              @click:clear="date_contract = null"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable></v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12">
                            <ValidationProvider ref="clients" rules="required|min:1" v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('clients')}}</div>
                                <v-combobox v-model="clients" :items="clientItems"
                                            :error-messages="errors"
                                            :error="!valid"
                                            :search-input.sync="clientSearching" hide-selected
                                            item-text="name" item-value="id" :loading="loadingClients"
                                             :disabled="loading"
                                            :label="$t('clients')" @click:clear="clientItems=[]"
                                            @click="clearClients" @input="clearEmptyClientItems(clients)"
                                            multiple return-object autocomplete="off"
                                            outlined
                                            solo
                                            flat
                                            dense
                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                            clearable>
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                        <span class="search_list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty" v-text="item.email"></span>
                                        </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <v-chip v-if="item === Object(item)"
                                                class="input_chip"
                                                v-bind="attrs" :input-value="selected"
                                                :to="{ name: 'client.profile', params: { id: item.id }}"
                                                @click:close="parent.selectItem(item)"
                                                :color="item.active ? 'green lighten-5' : 'red  lighten-5'"
                                                :title="item.active ? $t('active') : $t('no_active')"
                                                link close>
                                            <span v-html="item.name"></span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ clientSearching ? $t('nothing_found_by', {'search': clientSearching}) : $t('nothing_found_client_name')}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                            <ValidationProvider ref="note" rules="min:3|max:65535"
                                                v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('notes')}}</div>
                                <v-textarea v-model="note" type="text" :error-messages="errors"
                                            :disabled="loading" :label="$t('notes')" rows="5"
                                            color="primary"
                                            auto-grow
                                            clearable outlined
                                            hide-details
                                            full-width
                                            background-color="white lighten-2"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                >
                                </v-textarea>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row v-for="item in paymentAccountCompaniesItems" :key="item.id">
                        <v-col cols="12" sm="4">
                            <label class="client_title">{{$t('bank_name')}}</label>
                            <div class="client_text">{{item.name}}</div>
                        </v-col>
                        <v-col cols="12" sm="3" >
                            <label class="client_title">{{$t('BIC_bank')}}</label>
                            <div class="client_text">{{item.bik}}</div>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label class="client_title">{{$t('account_number')}}</label>
                            <div class="client_text">{{item.currency.letter_code}}: {{item.payment_account}}</div>
                        </v-col>
                        <v-col cols="12" sm="1" class="d-flex justify-end">
                            <v-btn @click="deletePaymentAccountCompany(item)" icon>
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="($route.name === 'company.edit' && addBank)">
                        <v-col cols="12" class="pt-5 pb-0 my-0 text-right">
                            <v-btn
                                icon
                                x-small
                                @click="addBank = false"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="py-2 pt-0" cols="12" sm="8">
                            <label class="input_label">{{$t('bank_name')}}</label>
                            <ValidationProvider ref="bank_name" rules="required|min:2|max:255"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="bank_name" type="text"
                                              :error-messages="errors"
                                              :disabled="loading"
                                              :hide-details="(valid || bank_name=== null) ? true : false"
                                              :label="$t('bank_name')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              height="46"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-2 pt-0" cols="12" sm="4">
                            <label class="input_label">{{$t('BIC_bank')}}</label>
                            <ValidationProvider ref="bik_bank" rules="required|min:2|max:50"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="bik_bank" type="text"
                                              :error-messages="errors"
                                              :disabled="loading"
                                              :hide-details="(valid || bik_bank=== null) ? true : false"

                                              :label="$t('BIC_bank')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              height="46"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-2" cols="12" sm="4">
                            <label class="input_label">{{$t('currency')}}</label>
                            <ValidationProvider ref="currency" rules="required" v-slot="{ errors, valid }">
                                <v-select v-model="currency" :items="currencyItems"
                                          item-text="letter_code" item-value="id"
                                          :error-messages="errors"
                                          :disabled="loading"
                                          :hide-details="(valid || currency=== null) ? true : false"

                                          :label="$t('currency')"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          height="46"
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                          clearable
                                ></v-select>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-2" cols="12" sm="8">
                            <label class="input_label">{{$t('account_number')}}</label>
                            <ValidationProvider ref="payment_account" rules="required|min:2|max:150"
                                                v-slot="{ errors, valid }">
                                <v-text-field v-model="payment_account" type="text"
                                              :error-messages="errors"
                                              :disabled="loading"
                                              :hide-details="(valid || payment_account=== null) ? true : false"

                                              :label="$t('account_number')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              height="46"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-3 d-flex justify-end" cols="12" sm="12">
                            <v-btn type="text" :loading="loading" color="primary"
                                   dense
                                   class="infinity_button"
                                   @click="savePaymentAccountCompany()"
                                   :disabled="invalid || loading"
                            >
                                {{ $t('add') }}
                            </v-btn>
                        </v-col>

                    </v-row>
                    <v-row v-if="($route.name === 'company.edit' && !addBank)">
                        <v-col class="py-3" cols="12" >
                            <v-btn type="text"
                                   color="primary"
                                   class="infinity_button"
                                   @click="addBank = true"
                            >
                                {{ paymentAccountCompaniesItems.length > 0 ? $t('add_more') : $t('add_checking_account') }}
                            </v-btn>

                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col class="py-0" cols="12">
                            <ValidationProvider ref="contract" rules=""
                                                v-slot="{ errors, valid }">


                                <v-col class="px-0 mx-0" cols="12" sm="12" v-if="!showDataContract(data_contract)">
                                    <v-btn
                                        elevation="1"
                                        @click="addContract"
                                    >
                                        {{ $t('attach_contract')}}
                                    </v-btn>
                                </v-col>

                                <v-col class="px-0 mx-0 py-0 my-0" cols="12" sm="12" v-if="showDataContract(data_contract)">
                                    <v-list subheader >
                                        <v-list-item class="px-0 mx-0"  @click="downloadFile(data_contract)">
                                            <v-list-item-icon>
                                                <v-img
                                                    max-width="90"
                                                    :alt="`${data_contract.file_name}`"
                                                    :src="data_contract.url"
                                                    max-height="60"
                                                ></v-img>
                                                <v-icon
                                                    x-large
                                                    v-if="!mimeTypeImage(data_contract.file_type)">mdi-file-document-outline mdi-48px</v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="data_contract.file_name"></v-list-item-title>
                                                <v-list-item-subtitle v-text="(data_contract.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-icon class="d-flex align-self-center mt-2">
                                                <v-btn
                                                    icon
                                                    @click="clearContract"
                                                >
                                                    <v-icon> mdi-delete-outline</v-icon>
                                                </v-btn>

                                            </v-list-item-icon>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-file-input v-model="contract"
                                              class="px-0 mx-0"
                                              id="contract_input"
                                              hide-input
                                              prepend-icon=""
                                              accept="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword"
                                              :disabled="loading"
                                              :error="!valid"
                                              @change="previewContract"
                                ></v-file-input>

                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col class="py-0" cols="12">
                            <v-switch v-model="active" :disabled="loading || deleted"
                                      :label="$t('company_active')" class="input_switch py-0"
                                      color="primary" hide-details></v-switch>
                        </v-col>
                    </v-row>






                </v-card-text>
                <v-card-actions class="py-6">
                    <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                       :active="loading" class="mx-2"></v-progress-linear>
                    <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                    <v-btn @click="saveCompany" :disabled="invalid || loading" :loading="loading"
                           :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>

    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mask} from 'vue-the-mask'
    import {mapGetters} from "vuex"
    import debounce from "lodash/debounce"
    import MakeLogo from '../components/MakeLogo.vue'

    export default {
        name: 'CompanyForm',
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            MakeLogo
        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                progress: 0,
                tab: 0,
                phoneRaw: null,
                phoneAdditionalRaw: null,
                company: null,
                name_legal: null,
                name_director: null,
                address_actually: null,
                address_legal: null,
                contract_number: null,
                company_uuid: null,
                email: null,
                bin_iin: null,
                logo: null,
                note: null,
                description: null,
                active: false,
                deleted: false,
                loading: false,
                loadingClients: false,
                date_contract: null,
                dateDialogContract: false,
                clients: [],
                clientItems: [],
                clientSearching: null,
                exist_translations: {},
                all_translations: true,

                property: null,
                propertyItems: [],
                paymentAccountCompaniesItems: [],
                addBank: false,
                bank_name: null,
                bik_bank: null,
                payment_account: null,
                currency: null,
                currencyItems: [],
                contract: null,
                data_contract: {},
                new_contract: 0,

            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang', 'phoneMask']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
            phoneAdditional: function () {
                return this.changePhone(this.phoneAdditionalRaw)
            },
            languages() {
                return this.listLanguages
            },
            tinymceInit() {
                return this.tinymceInitDefault()
            }
        },
        watch: {
            clientSearching: debounce(function (val) {
                if (val) {
                    this.getClients(val)
                }
            }, 500)
        },
        async mounted() {
            this.language = this.languages[this.tab]
            await this.getFormProperties();
            await this.getCurrencies();
            await this.checkCreate();
        },
        methods: {
            async setLanguage(val) {
                this.language = this.languages[val]
                await this.checkCreate()
            },
            clearEmptyClientItems(items) {
                if (items && items.length > 0) {
                    this.clients = items.filter(item => typeof item === 'object')
                }
            },
            clearClients() {
                if (!this.clients) {
                    this.clientItems = []
                }
            },

            checkCreate() {
                if (this.$route.name === "company.create") {
                    this.heading = this.$t('company_creation')
                } else {
                    this.heading = this.$t('company_editing')
                    if (this.$route.params.id) {
                        this.getCompany();
                    }
                }
            },


            async getCurrencies() {
                let params = {};
                params.filter = 'search';
                await this.$http
                    .get(`admin/currency`, {
                        params: params,
                    })
                    .then(res => {
                        this.currencyItems = res.body.data
                    })
                    .catch(err => {
                        this.currencyItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_currencies'))
                    });
            },
            async getFormProperties() {
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/property`, {
                        params: params,
                    })
                    .then(res => {
                        this.propertyItems = res.body.data
                    })
                    .catch(err => {
                        this.propertyItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_properties'))
                    });
            },
            async getClients(str) {
                if (str) {
                    this.loadingClients = true
                    let params = {};
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.client = str;
                    }
                    await this.$http
                        .get("admin/client", {
                            params: params,
                        })
                        .then(res => {
                            this.clientItems = res.body.data;
                        })
                        .catch(err => {
                            this.clientItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_clients'))
                        })
                        .finally(end => {
                            this.loadingClients = false
                        })
                }
            },
            async getCompany() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {};
                if (this.languages && this.languages[this.tab]) {
                    params.language = this.languages[this.tab]
                }
                await this.$http
                    .get(`admin/company/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.company = res.body.data.name
                        this.company_uuid = res.body.data.uuid
                        this.name_legal = res.body.data.name_legal
                        this.property = res.body.data.form_property_id
                        this.name_director = res.body.data.name_director
                        this.address_actually = res.body.data.address_actually
                        this.address_legal = res.body.data.address_legal
                        this.contract_number = res.body.data.contract_number
                        this.phoneRaw = res.body.data.phone
                        this.phoneAdditionalRaw = res.body.data.phone_additional
                        this.bin_iin = res.body.data.bin_iin
                        this.email = res.body.data.email
                        this.date_contract = res.body.data.date_contract
                        this.active = res.body.data.active
                        this.deleted = res.body.data.deleted
                        this.note = res.body.data.note
                        this.description = res.body.data.description
                        this.logo = res.body.data.logo
                        this.clients = res.body.data.clients
                        this.paymentAccountCompaniesItems = res.body.data.payment_accounts
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations

                        if(res.body.data && res.body.data.contract){
                            this.data_contract = res.body.data.contract
                        }
                        if(res.body.data.contract_base64){
                            this.contract  = new File([atob(res.body.data.contract_base64)], res.body.data.contract.file_name, {
                                type: "application/pdf",
                            })
                        }

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_company'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveCompany() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.property) {
                    formData.append('property', this.property)
                }
                if (this.company) {
                    formData.append('company', this.company)
                }
                if (this.name_legal) {
                    formData.append('name_legal', this.name_legal)
                }
                if (this.name_director) {
                    formData.append('name_director', this.name_director)
                }
                if (this.phone) {
                    formData.append('phone', this.phone)
                }
                if (this.phoneAdditional) {
                    formData.append('phone_additional', this.phoneAdditional)
                }
                if (this.email) {
                    formData.append('email', this.email)
                }
                if (this.bin_iin) {
                    formData.append('bin_iin', this.bin_iin)
                }
                if (this.address_actually) {
                    formData.append('address_actually', this.address_actually)
                }
                if (this.address_legal) {
                    formData.append('address_legal', this.address_legal)
                }
                if (this.contract_number) {
                    formData.append('contract_number', this.contract_number)
                }
                if (this.date_contract) {
                    formData.append('date_contract', this.date_contract)
                }
                if (this.note) {
                    formData.append('note', this.note)
                }
                if (this.description) {
                    formData.append('description', this.description)
                }
                if (this.active) {
                    formData.append('active', 1)
                }
                if (this.deleted) {
                    formData.append('deleted', 1)
                }
                if (this.new_contract) {
                    formData.append('new_contract', 1)
                }
                if (this.contract) {
                    formData.append('contract', this.contract)
                }

                if (this.data_contract.size) {
                    formData.append('contract_size', this.data_contract.size)
                }
                if (this.data_contract.file_name) {
                    formData.append('contract_file_name', this.data_contract.file_name)
                }
                if (this.data_contract.file_type) {
                    formData.append('contract_file_type', this.data_contract.file_type)
                }

                if (this.clients && this.clients.length > 0) {
                    for (let i in this.clients) {
                        if (this.clients[i].id !== undefined && this.clients[i].id > 0) {
                            formData.append(`clients[${i}]`, this.clients[i].id)
                        }
                    }
                }

                if (this.logo) {
                    if (this.logo.length > 250) {
                        var mimeTypeLogo = this.getMimeType(this.logo)
                        var blobLogo = this.dataURL64toBlob(this.logo)
                        if (mimeTypeLogo && blobLogo) {
                            formData.append('logo', blobLogo, mimeTypeLogo)
                        }
                    } else {
                        formData.append('logo', this.logo)
                    }
                }

                if (this.$route.params.id) {
                    // Save
                    await this.$http
                        .put(`admin/company/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('company_has_been_updated'));
                            if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('company_has_not_been_updated'));
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/company', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('company_has_been_added'));
                            this.$router.push({
                                name: 'company'
                            })
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                                this.$router.push({
                                    name: 'company.profile',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: 'company'
                                })
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('company_has_not_been_added'));
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            async getPaymentAccountCompanies() {
                let params = {};
                params.filter = 'all';
                params.company_uuid = this.company_uuid;
                await this.$http
                    .get(`admin/company/payment_account`, {
                        params: params,
                    })
                    .then(res => {
                        this.paymentAccountCompaniesItems = res.body.data
                        if(this.paymentAccountCompaniesItems.length === 0){
                            this.addBank = true
                        }
                    })
                    .catch(err => {
                        this.paymentAccountCompaniesItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_payment_account_companies'))
                    });
            },
            async savePaymentAccountCompany(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.company_uuid) {
                    formData.append('company_uuid', this.company_uuid)
                }
                if (this.currency) {
                    formData.append('currency', this.currency)
                }

                if (this.payment_account) {
                    formData.append('payment_account', this.payment_account)
                }
                if (this.bik_bank) {
                    formData.append('bik_bank', this.bik_bank)
                }
                if (this.bank_name) {
                    formData.append('bank_name', this.bank_name)
                }

                // Save
                await this.$http
                    .post(`admin/company/payment_account`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('payment_account_company_has_been_updated'));
                        if (res && res.body && res.body.status && res.body.status === 'success' ) {
                            this.getPaymentAccountCompanies();
                            this.bank_name = null;
                            this.bik_bank = null;
                            this.payment_account = null;
                            this.currency = null;
                            this.addBank = false
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('payment_account_has_not_been_updated'));
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deletePaymentAccountCompany(item) {
                if (confirm(this.$t('delete_payment_account_company'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/company/${this.company_uuid}/payment_account/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('payment_account_company_has_been_deleted'))
                            this.getPaymentAccountCompanies()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('payment_account_company_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            showDataContract(item){
                if(item){
                    if(item.file_name){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                return false;
            },
            previewContract() {
                let document = this.contract;
                this.data_contract = {}
                this.data_contract.url = URL.createObjectURL(document)
                this.data_contract.file_name = document.name
                this.data_contract.blob =  document
                this.data_contract.size = document.size
                this.data_contract.file_type = document.type
                this.new_contract = 1;
            },
            clearContract(){
                this.contract = null
                this.data_contract  = {}
            },
            addContract(){
                document.getElementById('contract_input').click()
            },
            downloadFile(data) {
                fetch(data.url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = data.file_name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))

                    );
            },
        }
    }
</script>
